import styled from '@emotion/styled/macro';
import { faGripLinesVertical } from '@fortawesome/pro-regular-svg-icons';
import {
	faBars,
	faChevronDown,
	faChevronUp,
	faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ENDPOINT_LOADING } from '../app-hooks/endpoint-caching';
import { useSafeRoutingParameters } from '../app-hooks/route-parameters';
import { SITEMAP_NOT_FOUND, useSitemap } from '../app-hooks/sitemap';
import useWindowWidth from '../app-hooks/useWindowWidth';
import { Anchor, Spinner } from '../content-components';
import type { SitemapJson } from '../types';

const LeftSidebarCloseIconContainer = styled.div`
	align-items: center;
	border-bottom: solid 1px var(--legacy-color-medium-grey);
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: var(--spacing-small);

	> span > svg {
		margin-right: var(--spacing-small);
	}
`;

const LeftSidebarCloseIcon: FunctionComponent<{ toggleIsOpen(): void }> = ({
	toggleIsOpen,
}) => {
	const windowWidth = useWindowWidth();
	if (windowWidth > 1000) {
		return null;
	}
	return (
		<LeftSidebarCloseIconContainer onClick={toggleIsOpen}>
			<span>
				<FontAwesomeIcon icon={faTimes} />
				Close
			</span>
		</LeftSidebarCloseIconContainer>
	);
};

// > a styles the <Link /> rendered inside a MenuItem.
// > span styles the text if the item itself is not linkable
const StyledLeftSidebarMenuItem = styled.li<{
	isActive: boolean;
	isAncestorOfActive: boolean;
	level: number;
}>`
	flex: none;

	display: flex;
	flex-direction: row;

	margin: 0;
	padding: 0;

	> a,
	> span {
		display: flex;
		flex-direction: row;

		align-items: center;

		padding-top: var(--spacing-medium-small);
		padding-bottom: var(--spacing-medium-small);
		padding-left: ${({ isActive, level }) =>
			isActive
				? `calc(${level} * var(--spacing-medium-small) - 8px)`
				: `calc(${level} * var(--spacing-medium-small))`};
		padding-right: var(--spacing-medium-small);

		width: 100%;

		text-decoration: none;
		font-family: var(--font-primary);
		font-size: ${({ level }) => (level <= 0 ? '16px' : '14px')};
		font-weight: ${({ isActive, isAncestorOfActive, level }) =>
			isActive
				? 'var(--font-weight-bold)'
				: isAncestorOfActive || level <= 0
				? 'var(--font-weight-subtle)'
				: 'var(--font-weight-normal)'};
		font-stretch: normal;
		font-style: normal;
		line-height: var(--spacing-medium-small);
		letter-spacing: normal;

		color: var(--color-grey-800);
		background-color: ${({ isActive, isAncestorOfActive }) =>
			isActive || isAncestorOfActive
				? `var(--color-grey-200)`
				: `transparent`};
		border-bottom: solid 1px var(--legacy-color-medium-grey);
		box-shadow: ${({ isActive }) =>
			// @TODO color palette
			isActive ? `inset 0px 1px 2px 1px rgba(0, 0, 0, 0.16)` : `none`};

		border-left: ${({ isActive = false }) =>
			isActive ? '8px solid var(--color-grey-800)' : 'none'};

		overflow-wrap: anywhere;

		div {
			flex: none;
			margin-right: ${({ isActive, level }) =>
				isActive && level === 0
					? `calc(var(--spacing-small) - 8px)`
					: `var(--spacing-small)`};
		}
	}
	> a {
		cursor: pointer;
		outline: none;

		&:hover {
			background-color: var(--color-purple-50);
		}
	}
`;
const LeftSidebarMenuItem: FunctionComponent<
	PropsWithChildren & {
		level: number;
		menuItem: SitemapJson;
	}
> = ({ children, level, menuItem }) => {
	const { path } = useSafeRoutingParameters();
	const isActive = menuItem.href === path;
	const isAncestorOfActive = menuItem.children.some(function r(item) {
		return item.href === path || item.children.some(r);
	});

	const [isCollapsed, setIsCollapsed] = useState(
		!isAncestorOfActive && !isActive
	);
	const toggleIsCollapsed = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			event.preventDefault();
			event.stopPropagation();
			// event.stopImmediatePropagation();
			setIsCollapsed((isCollapsed) => !isCollapsed);
		},
		[]
	);

	useEffect(() => {
		if (isAncestorOfActive || isActive) {
			setIsCollapsed(false);
			return;
		}
		setIsCollapsed(true);
	}, [isAncestorOfActive, isActive]);

	const icon =
		menuItem.children.length > 0 && level > 0 ? (
			<div onClick={toggleIsCollapsed}>
				<FontAwesomeIcon
					icon={isCollapsed ? faChevronDown : faChevronUp}
					fixedWidth
					color="var(--color-grey-400)"
				/>
			</div>
		) : (
			<div>
				<FontAwesomeIcon
					icon={faGripLinesVertical}
					fixedWidth
					style={{ visibility: 'hidden' }}
				/>
			</div>
		);

	return (
		<>
			<StyledLeftSidebarMenuItem
				level={level}
				isActive={!!isActive}
				isAncestorOfActive={!!isAncestorOfActive}
			>
				<Anchor href={menuItem.href}>
					{icon}
					{menuItem.label}
				</Anchor>
				{children}
			</StyledLeftSidebarMenuItem>

			{!isCollapsed &&
				menuItem.children.length > 0 &&
				menuItem.children.map((childItem, index) => (
					<LeftSidebarMenuItem
						key={`${menuItem.href}${index}`}
						level={level + 1}
						menuItem={childItem}
					/>
				))}
		</>
	);
};

// This has the height of the full viewport (just like its sibling ContentAndRightSidebarScrollContainer).
const LeftSidebarSurface = styled.aside`
	flex: none;
	width: var(--sidebars-width);
	background-color: var(--color-grey-50);
	overflow-y: scroll;
	overscroll-behavior: contain;

	@media (max-width: 1000px) {
		z-index: 1;
		position: absolute;
		top: 0;
		bottom: 0;
		display: none;
		&.sidebar-is-opened {
			display: block;
		}
	}
`;

const LeftSidebarLightbox = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: var(--sidebars-width);
	right: 0;

	background-color: rgba(0, 0, 0, 0.16);

	z-index: 1;

	display: none;
	@media (max-width: 1000px) {
		&.sidebar-is-opened {
			display: block;
		}
	}
`;

const LeftSidebarMenuItemsList = styled.ul`
	user-select: none;
	display: flex;
	flex-direction: column;

	margin: 0;
	padding: 0;
`;
export const LeftSidebarMenu: FunctionComponent<{
	isOpen: boolean;
	toggleIsOpen(): void;
}> = ({ isOpen, toggleIsOpen }) => {
	const sidebarStateClassName = isOpen
		? 'sidebar-is-opened'
		: 'sidebar-is-closed';
	const sitemap = useSitemap();
	const { path } = useSafeRoutingParameters();

	const currentTld = useMemo(() => {
		if (sitemap !== ENDPOINT_LOADING && sitemap !== SITEMAP_NOT_FOUND) {
			return sitemap[0].children.find(function r(category) {
				return category.href === path || category.children.some(r);
			});
		}
		return null;
	}, [sitemap, path]);

	const sidebarContents = useMemo(() => {
		if (sitemap === ENDPOINT_LOADING) {
			// @TODO sidebar is loading
			return (
				<div
					style={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Spinner />
				</div>
			);
		}
		if (sitemap === SITEMAP_NOT_FOUND) {
			// @TODO browsing a page that is not in the sitemap
			return null;
		}

		if (!currentTld) {
			// @TODO browsing a page that is not in the sitemap
			return null;
		}

		return (
			<LeftSidebarMenuItemsList>
				<LeftSidebarMenuItem
					key={currentTld.href}
					level={0}
					menuItem={currentTld}
				/>
			</LeftSidebarMenuItemsList>
		);
	}, [sitemap, currentTld]);

	return sidebarContents ? (
		<>
			<LeftSidebarLightbox className={sidebarStateClassName} />
			<LeftSidebarSurface
				className={sidebarStateClassName}
				key={currentTld?.href}
			>
				<LeftSidebarCloseIcon toggleIsOpen={toggleIsOpen} />
				{sidebarContents}
			</LeftSidebarSurface>
		</>
	) : null;
};

const LeftSidebarMobileButtonContainer = styled.div`
	flex: none;
	display: flex;
	align-items: center;
	justify-content: center;

	z-index: 1;
	position: absolute;
	top: 0;
	left: 0;

	height: 50px;
	width: 50px;
	border-bottom-left-radius: var(--border-radius-small);
	border-bottom-right-radius: var(--border-radius-small);

	margin-left: var(--spacing-small);

	box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.16);
	background-color: var(--color-grey-50);

	cursor: pointer;

	display: none;
	@media (max-width: 1000px) {
		display: flex;
	}
`;

export const LeftSidebarMobileButton: FunctionComponent<{
	isOpen: boolean;
	toggleIsOpen(): void;
}> = ({ isOpen, toggleIsOpen }) => {
	const sidebarStateClassName = isOpen
		? 'sidebar-is-opened'
		: 'sidebar-is-closed';
	return (
		<LeftSidebarMobileButtonContainer
			className={sidebarStateClassName}
			onClick={toggleIsOpen}
		>
			{' '}
			<FontAwesomeIcon icon={faBars} color="var(--color-grey-800)" />
		</LeftSidebarMobileButtonContainer>
	);
};

export const LeftSidebar: FunctionComponent<{ isEnabled: boolean }> = ({
	isEnabled,
}) => {
	const [isMobileSidebarOpen, setIsSideBarMenuOpen] = useState(false);
	const toggleIsSideBarMenuOpen = useCallback(() => {
		setIsSideBarMenuOpen((isSideBarMenuOpen) => !isSideBarMenuOpen);
	}, []);
	if (!isEnabled) {
		return null;
	}

	return (
		<>
			<LeftSidebarMobileButton
				isOpen={isMobileSidebarOpen}
				toggleIsOpen={toggleIsSideBarMenuOpen}
			/>
			<LeftSidebarMenu
				isOpen={isMobileSidebarOpen}
				toggleIsOpen={toggleIsSideBarMenuOpen}
			/>
		</>
	);
};
