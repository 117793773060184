import styled from '@emotion/styled/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCertificate,
	faCheckSquare,
	faChevronDown,
	faChevronUp,
	faExclamationTriangle,
	faInfoCircle,
	faSquare,
} from '@fortawesome/pro-regular-svg-icons';
import {
	evaluateXPathToBoolean,
	evaluateXPathToNumber,
	evaluateXPathToString,
} from 'fontoxpath';
import { useState } from 'react';
import { ReactRenderer } from 'xml-renderer';

import { ChildPageList } from '../../app-components/child-page-list';
import { DeeplinkButton } from '../../app-components/deep-linking';
import { useLayoutExpectation } from '../../app-hooks/layouting';
import { useSafeRoutingParameters } from '../../app-hooks/route-parameters';
import {
	Anchor,
	Bold,
	BrokenAnchor,
	ClickablePanel,
	ClickablePanels,
	CodeBlock,
	CodePhrase,
	CollapsibleBox,
	CollapsibleBoxGroup,
	Figure,
	getSupportedLanguageForDitaValue,
	Heading,
	HorizontalClickablePanel,
	HorizontalClickablePanels,
	HorizontalRuler,
	Image,
	Italic,
	ListItem,
	Note,
	OrderedList,
	Paragraph,
	Section,
	Subscript,
	Superscript,
	Table,
	TableCell,
	TableHeader,
	TableRow,
	Underline,
	UnorderedList,
} from '../../content-components';
import { VideoFigure } from '../../content-components/atoms/images';
import {
	CONTENT_CALLBACK_AND_URL_BY_URN,
	ObjectIframe,
} from '../../content-components/atoms/object-iframe';
import { TaskSection } from '../../content-components/atoms/sections';
import {
	PrereqWrapper,
	Result,
	TaskStep,
	TaskStepCollapse,
	TaskStepCommand,
	TaskStepContent,
	TaskStepHeader,
	TaskStepList,
	TaskStepNumber,
	TaskStepSection,
	TaskSubStepContent,
	TaskSubStepNumber,
} from '../../content-components/atoms/tasks';
import { TutorialInfo } from '../../content-components/atoms/tutorialinfo';
import {
	UpgradeInstruction,
	UpgradeInstructionCheckbox,
	UpgradeInstructionCommand,
	UpgradeInstructionContent,
	UpgradeInstructionHeader,
	UpgradeInstructionNumber,
} from '../../content-components/atoms/upgrade-instructions';
import { VideoPlayer } from '../../content-components/atoms/video';
import { getDitaCalsCellSpan } from '../querying/dita-cals-functions';
import type { RuleContext } from '../types';
import sharedElementRules from './shared';

const rules = new ReactRenderer<RuleContext>(sharedElementRules);

// TODO
const Pre = styled.pre`
	margin: 0;
	padding-top: 6px;
`;
rules.add('self::pre', ({ traverse }) => <Pre>{traverse()}</Pre>);

/**
 * Code blocks
 */

// DITA <fig> containing <codeblock>, and possibly <title>
rules.add('self::fig[child::codeblock]', ({ traverse, node }) => {
	const xmlLang = evaluateXPathToString('./codeblock/@xml:lang', node);
	return (
		<Figure borderless>
			<CodeBlock
				// Do not traverse but rather just get the title contents.
				title={evaluateXPathToString('./title/node()', node)}
				language={getSupportedLanguageForDitaValue(xmlLang)}
			>
				{traverse('./codeblock')}
			</CodeBlock>
			{traverse('./desc')}
		</Figure>
	);
});

rules.add('self::codeblock', ({ traverse }) => <>{traverse()}</>);

/**
 * Video
 */

rules.add('self::fig[child::object]', ({ traverse }) => (
	<VideoFigure isFullWidth>
		{traverse('child::title')}
		{traverse('child::desc')}
		{traverse('child::object')}
	</VideoFigure>
));

rules.add('self::object[parent::fig]', ({ node }) => (
	<VideoPlayer src={evaluateXPathToString('./@data', node)} />
));

rules.add('self::section[parent::taskbody]', ({ traverse }) => {
	return <TaskSection>{traverse()}</TaskSection>;
});

/**
 * Same and similar elements
 */

rules.add('self::fig', ({ traverse }) => (
	<Figure>
		{traverse('child::*[not(self::desc)]')}
		{traverse('child::desc')}
	</Figure>
));

rules.add('self::codeph', ({ traverse }) => (
	<CodePhrase>{traverse()}</CodePhrase>
));

rules.add('self::ph', ({ traverse }) => <>{traverse()}</>);

rules.add('self::p', ({ traverse }) => <Paragraph>{traverse()}</Paragraph>);

rules.add('self::ul', ({ traverse }) => (
	<UnorderedList>{traverse()}</UnorderedList>
));

rules.add('self::ol', ({ traverse }) => (
	<OrderedList>{traverse()}</OrderedList>
));

rules.add('self::li', ({ traverse }) => <ListItem>{traverse()}</ListItem>);

// #############
// :
// :   DITATASK ELEMENT RULES
// :
// <<<<<<<

rules.add('self::prereq', ({ traverse }) => (
	<PrereqWrapper>
		<Heading level={3}>What you need</Heading>
		{traverse()}
	</PrereqWrapper>
));

rules.add('self::steps', ({ traverse }) => (
	<TaskStepList>{traverse()}</TaskStepList>
));

rules.add('self::step', ({ traverse, node }) => {
	const stepId = evaluateXPathToString('@id', node);
	const stepNumber = evaluateXPathToString(
		`string-join(./(ancestor-or-self::step, ancestor-or-self::substep) ! (count((preceding-sibling::step, preceding-sibling::substep)) + 1), '.')`,
		node
	);

	const [isVisible, setIsVisible] = useState(() => {
		const savedState = localStorage.getItem(`${stepId}-visible`);
		return savedState !== null ? JSON.parse(savedState) : true;
	});

	const handleVisibilityToggle = () => {
		const newState = !isVisible;
		setIsVisible(newState);
		localStorage.setItem(`${stepId}-visible`, JSON.stringify(newState));
	};

	return (
		<TaskStep id={`step-${stepNumber}`}>
			<TaskStepHeader>
				<TaskStepNumber>{stepNumber}</TaskStepNumber>
				<TaskStepCommand>
					<Heading level={3} deepLinkableId={`step-${stepNumber}`}>
						{traverse('./cmd')}
					</Heading>
				</TaskStepCommand>
				<TaskStepCollapse onClick={() => handleVisibilityToggle()}>
					{isVisible ? (
						<FontAwesomeIcon icon={faChevronUp} title="Expand" />
					) : (
						<FontAwesomeIcon
							icon={faChevronDown}
							title="Collapse"
						/>
					)}
				</TaskStepCollapse>
			</TaskStepHeader>
			{isVisible && (
				<TaskStepContent>
					{traverse('./*[not(self::cmd)]')}
				</TaskStepContent>
			)}
		</TaskStep>
	);
});

rules.add('self::substeps', ({ traverse }) => (
	<TaskStepList>{traverse()}</TaskStepList>
));

rules.add('self::substep', ({ traverse, node }) => {
	const stepId = evaluateXPathToString('@id', node);
	const stepNumber = evaluateXPathToString(
		`string-join(./(ancestor-or-self::step, ancestor-or-self::substep) ! (count((preceding-sibling::step, preceding-sibling::substep)) + 1), '.')`,
		node
	);

	const [isVisible, setIsVisible] = useState(() => {
		const savedState = localStorage.getItem(`${stepId}-visible`);
		return savedState !== null ? JSON.parse(savedState) : true;
	});

	const handleVisibilityToggle = () => {
		const newState = !isVisible;
		setIsVisible(newState);
		localStorage.setItem(`${stepId}-visible`, JSON.stringify(newState));
	};

	return (
		<TaskStep id={stepId}>
			<TaskStepHeader>
				<TaskSubStepNumber>{stepNumber}</TaskSubStepNumber>
				<TaskStepCommand>
					<Heading level={4} deepLinkableId={`step-${stepNumber}`}>
						{traverse('./cmd')}
					</Heading>
				</TaskStepCommand>
				<TaskStepCollapse onClick={() => handleVisibilityToggle()}>
					{isVisible ? (
						<FontAwesomeIcon icon={faChevronUp} title="Expand" />
					) : (
						<FontAwesomeIcon
							icon={faChevronDown}
							title="Collapse"
						/>
					)}
				</TaskStepCollapse>
			</TaskStepHeader>
			{isVisible && (
				<TaskSubStepContent>
					{traverse('./*[not(self::cmd)]')}
				</TaskSubStepContent>
			)}
		</TaskStep>
	);
});

rules.add(
	`
	self::result or
	self::stepresult
	`,
	({ traverse }) => <Result>{traverse()}</Result>
);

rules.add('self::cmd', ({ traverse }) => <>{traverse()}</>);

rules.add('self::stepsection', ({ traverse }) => (
	<TaskStepSection>{traverse()}</TaskStepSection>
));

rules.add('self::tutorialinfo', ({ traverse, node }) => {
	const showHeading = evaluateXPathToBoolean(
		'not(preceding-sibling::tutorialinfo) and following-sibling::tutorialinfo and ../info',
		node
	);

	return <TutorialInfo showHeading={showHeading}>{traverse()}</TutorialInfo>;
});

// The following DITA Task rules are skipped because the XML elements do not translate to DOM elements
rules.add('self::context', ({ traverse }) => <>{traverse()}</>);

rules.add('self::info', ({ traverse }) => <>{traverse()}</>);

rules.add('self::tasktroubleshooting', ({ traverse }) => <>{traverse()}</>);

// #############
// :
// :   DITA TABLE ELEMENT RULES
// :
// <<<<<<<

function hasRunningText(node: Node): boolean {
	return evaluateXPathToBoolean(
		'.[string-length(normalize-space(.)) >= 30]',
		node
	);
}

rules.add('self::simpletable', ({ traverse }) => (
	<Table>{traverse('./(sthead|strow)')}</Table>
));

rules.add('self::sthead', ({ traverse }) => (
	<TableRow>{traverse('./stentry')}</TableRow>
));

rules.add('self::strow', ({ traverse }) => (
	<TableRow>{traverse('./stentry')}</TableRow>
));

rules.add('self::stentry', ({ traverse, node }) => (
	<TableCell isWide={hasRunningText(node)}>{traverse()}</TableCell>
));

rules.add('self::stentry[parent::sthead]', ({ traverse, node }) => (
	<TableHeader isWide={hasRunningText(node)}>{traverse()}</TableHeader>
));

// CALS tables
rules.add('self::table', ({ traverse }) => (
	<Table>{traverse('./tgroup/(thead|tbody)/*')}</Table>
));

rules.add('self::row', ({ traverse }) => (
	<TableRow>{traverse('./entry')}</TableRow>
));

rules.add('self::entry', ({ traverse, node }) => (
	<TableCell {...getDitaCalsCellSpan(node)} isWide={hasRunningText(node)}>
		{traverse()}
	</TableCell>
));

rules.add('self::entry[parent::row[parent::thead]]', ({ traverse, node }) => (
	<TableHeader {...getDitaCalsCellSpan(node)} isWide={hasRunningText(node)}>
		{traverse()}
	</TableHeader>
));

rules.add('self::colname', () => null);

// #############
// :
// :   DITA HORIZONTAL CLICKABLE PANEL ELEMENT RULES
// :
// <<<<<<<

rules.add(
	'self::div[@outputclass="gatsby-horizontal-clickable-panel-grid"]',
	({ traverse }) => (
		<HorizontalClickablePanels>{traverse()}</HorizontalClickablePanels>
	)
);

rules.add(
	'self::fig[parent::div[@outputclass="gatsby-horizontal-clickable-panel-grid"]]',
	({ traverse, node }) => (
		<HorizontalClickablePanel
			href={evaluateXPathToString(
				'./data[@name="gatsby-clickable-panel-reference"]/@href',
				node
			)}
			image={evaluateXPathToString('./image/@href', node)}
			imageAlternateText={evaluateXPathToString('./image/alt', node)}
			label={evaluateXPathToString('./title', node)}
		>
			{traverse('./desc/node()')}
		</HorizontalClickablePanel>
	)
);

// #############
// :
// :   DITA ELEMENT RULES
// :
// <<<<<<<

rules.add(
	`
		self::topic[not(parent::*)] or
		self::task[not(parent::*)] or
		self::faqlist[not(parent::*)]
	`,
	({ traverse, articleHeroWidget }) => {
		const { isGeneralOverview } = useLayoutExpectation(false);
		return (
			<Section>
				{isGeneralOverview ? null : (
					<>
						{traverse('./title')}
						{traverse('./shortdesc')}
					</>
				)}

				{articleHeroWidget}

				{traverse('./*[not(self::title or self::shortdesc)]')}
			</Section>
		);
	}
);

rules.add('self::topic[parent::*]', ({ traverse }) => (
	<Section>{traverse()}</Section>
));

rules.add(
	`self::topic[parent::topic[parent::topic[@outputclass="upgrade-instructions"]]]`,
	({ traverse, node }) => {
		const topicId = evaluateXPathToString('@id', node);
		const topicNumber = evaluateXPathToString(
			'count(preceding-sibling::topic) + 1',
			node
		);

		const [isDone, setIsDone] = useState(() => {
			const savedState = localStorage.getItem(`${topicId}-task`);
			return savedState !== null ? JSON.parse(savedState) : false;
		});

		const handleDoneToggle = () => {
			const newState = !isDone;
			setIsDone(newState);
			localStorage.setItem(`${topicId}-task`, JSON.stringify(newState));
		};

		return (
			<UpgradeInstruction>
				<UpgradeInstructionHeader id={topicId}>
					<UpgradeInstructionNumber>
						{topicNumber}
					</UpgradeInstructionNumber>
					<UpgradeInstructionCommand>
						{traverse('./title')}
					</UpgradeInstructionCommand>
					<UpgradeInstructionCheckbox
						onClick={() => handleDoneToggle()}
					>
						{!isDone ? (
							<FontAwesomeIcon
								icon={faSquare}
								title="In progress"
							/>
						) : (
							<FontAwesomeIcon
								icon={faCheckSquare}
								title="Done"
								color="var(--color-green-400)"
							/>
						)}
					</UpgradeInstructionCheckbox>
				</UpgradeInstructionHeader>
				{!isDone && (
					<UpgradeInstructionContent>
						{traverse('./*[not(self::title and position()=1)]')}
					</UpgradeInstructionContent>
				)}
			</UpgradeInstruction>
		);
	}
);

/**
 * Topic structural elements
 */

rules.add(
	`
		self::body or
		self::conbody or
		self::taskbody or
		self::refbody
	`,
	({ traverse }) => <Section>{traverse()}</Section>
);

rules.add('self::shortdesc', ({ traverse }) => (
	<Paragraph emphasis>{traverse()}</Paragraph>
));

rules.add('self::prolog', () => null);

rules.add('self::div', ({ traverse }) => <>{traverse()}</>);

rules.add('self::section', ({ traverse }) => <>{traverse()}</>);

/**
 * The rules below are for elements that are styled specifically from the page
 * template where they are used, because they need complicated layout containers
 * that are difficult to render recursively with the xml renderer.
 * See GeneralOverview.tsx for where/how this data is rendered instead.
 */

rules.add('self::fig[@outputclass="gatsby-header-image"]', () => null);

rules.add('self::section[@outputclass="gatsby-popular-keywords"]', () => null);

/**
 * Specific styling containers
 */

rules.add(
	'self::section[@outputclass="gatsby-inset-section"]',
	({ traverse }) => (
		<Section inset="var(--spacing-large)">{traverse()}</Section>
	)
);

rules.add(
	'self::title[parent::section[@outputclass="gatsby-inset-section"]]',
	({ traverse }) => <Heading level={2}>{traverse()}</Heading>
);

rules.add(
	'self::topic[@outputclass="gatsby-related-information"]',
	({ traverse }) => (
		<Section>
			<HorizontalRuler />
			{traverse()}
		</Section>
	)
);

rules.add(
	'self::xref[ancestor::topic[@outputclass="gatsby-related-information"] and @scope="external"]',
	({ node, traverse }) => {
		const href = evaluateXPathToString('./@href', node);
		return <Anchor href={href}>{traverse()}</Anchor>;
	}
);

/**
 * Generic inline elements
 */

// These formatting inlines are not supported in the editor, because more semantic tagging (usually <codeph>) is preferred.
rules.add('self::b', ({ traverse }) => <Bold>{traverse()}</Bold>);

rules.add('self::i', ({ traverse }) => <Italic>{traverse()}</Italic>);

rules.add('self::u', ({ traverse }) => <Underline>{traverse()}</Underline>);

rules.add('self::sub', ({ traverse }) => <Subscript>{traverse()}</Subscript>);

rules.add('self::sup', ({ traverse }) => (
	<Superscript>{traverse()}</Superscript>
));

rules.add('self::xref', ({ node, traverse }) => {
	const href = evaluateXPathToString('./@href', node);
	return <Anchor href={href}>{traverse()}</Anchor>;
});

rules.add('self::xref[@unresolved]', ({ traverse }) => {
	return <BrokenAnchor>{traverse()}</BrokenAnchor>;
});

rules.add('self::keyword', ({ traverse }) => <>{traverse()}</>);

/**
 * Images
 */

rules.add('self::image', ({ node }) => {
	return (
		<Image
			src={evaluateXPathToString('@href', node)}
			alt={evaluateXPathToString('./alt', node)}
		/>
	);
});

/**
 * Generic block-level elements
 */

rules.add('self::desc', ({ traverse }) => <>{traverse()}</>);

/**
 * Paragraphs in special places
 * @see also ./SharedElements.tsx
 */

rules.add('self::p[ancestor::abstract]', ({ traverse }) => (
	<Paragraph emphasis>{traverse()}</Paragraph>
));

/**
 * Titles, of various levels
 * @TODO a fallback styling for title (self::title)
 * @TODO Not style titles in figures as if they are section titles (H3)
 */

rules.add(
	'self::title[parent::topic or parent::task or parent::faqlist or parent::section]',
	({ node, traverse }) => {
		const headingLevel = evaluateXPathToNumber('count(ancestor::*)', node);
		const topicIdentifier = evaluateXPathToString('./parent::*/@id', node);

		return (
			<Heading
				level={headingLevel}
				id={topicIdentifier}
				deepLinkableId={topicIdentifier}
			>
				{traverse()}
			</Heading>
		);
	}
);

rules.add('self::title[parent::fig]', ({ traverse }) => (
	<Heading>{traverse()}</Heading>
));

/**
 * Notes, of various types
 */

rules.add('self::note', ({ traverse }) => (
	<Note type="neutral" icon={faInfoCircle}>
		{traverse()}
	</Note>
));

rules.add('self::note[@type="tip"]', ({ traverse }) => (
	<Note type="tip" icon={faCertificate}>
		{traverse()}
	</Note>
));

rules.add('self::note[@type="warning"]', ({ traverse }) => (
	<Note type="warning" icon={faExclamationTriangle}>
		{traverse()}
	</Note>
));

/**
 * Clickable panels
 */

rules.add(
	'self::div[@outputclass="gatsby-clickable-panel-list"]',
	({ traverse }) => <ClickablePanels>{traverse()}</ClickablePanels>
);

rules.add(
	'self::fig[parent::div[@outputclass="gatsby-clickable-panel-list"]]',
	({ traverse, node }) => {
		const image = evaluateXPathToString('./image/@href', node);
		return (
			<ClickablePanel
				href={evaluateXPathToString(
					'./data[@name="gatsby-clickable-panel-reference"]/@href',
					node
				)}
				image={image || undefined}
				imageAlternateText={evaluateXPathToString('./image/alt', node)}
				label={evaluateXPathToString('./title', node)}
			>
				{traverse('./desc/node()')}
			</ClickablePanel>
		);
	}
);

/**
 * Child page list macro
 */

rules.add('self::object[@name="gatsby-child-page-list"]', () => {
	return <ChildPageList />;
});

rules.add(`self::shortdesc[not(parent::*)]`, ({ traverse }) => (
	<Paragraph>{traverse()}</Paragraph>
));

/**
 * FAQ lists
 */

rules.add('self::faqlist[parent::*]', ({ traverse }) => (
	<Section inset="var(--spacing-large)">{traverse()}</Section>
));

rules.add('self::faqlistbody', ({ traverse }) => (
	<CollapsibleBoxGroup>{traverse()}</CollapsibleBoxGroup>
));

rules.add('self::faq', ({ node, traverse }) => {
	const { hash } = useSafeRoutingParameters();
	const id = evaluateXPathToString('./@id', node);
	return (
		<CollapsibleBox
			label={evaluateXPathToString('./question', node)}
			id={id}
			labelAside={<DeeplinkButton id={id} />}
			openedInitially={!!(hash && hash.substr(1) === id)}
		>
			{traverse()}
		</CollapsibleBox>
	);
});

// rules.add('self::question', () => null);
// rules.add('self::answer', ({ traverse }) => {
// 		return <Paragraph>{traverse()}</Paragraph>;
// });

rules.add('self::object[@outputclass="iframe"]', ({ node }) => {
	const { version } = useSafeRoutingParameters();
	const id = evaluateXPathToString('./@id', node);
	const data = evaluateXPathToString('./@data', node);
	const validURN = data as keyof typeof CONTENT_CALLBACK_AND_URL_BY_URN;
	const content = CONTENT_CALLBACK_AND_URL_BY_URN[validURN]?.getContent(node);

	if (!id || !version || !data || !content) {
		return null;
	}

	const url = CONTENT_CALLBACK_AND_URL_BY_URN[validURN].url;

	return (
		<ObjectIframe
			sdkVersionName={version}
			id={id}
			url={url}
			content={content}
		/>
	);
});

export default rules;
