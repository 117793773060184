import { css } from "@emotion/react";
import styled from "@emotion/styled";

/**
 * Shared styles for tasks.
 */
const taskStep = css`
    font-family: var(--font-secondary);
    font-weight: var(--font-weight-bold);
    flex: 0 0 auto;
    color: var(--color-grey-800);
`;

const taskStepContent = css`
    // Copied from BodyContainer in ditaElementRules.tsx
    > * + * {
        margin-top: var(--spacing-medium-large) !important;
    }

    h4 + p {
        margin-top: 0 !important;
    }
`;

/**
 * Styled components for tasks.
 */
const PrereqWrapper = styled.section`
    border: solid 1px var(--color-grey-500);
    background-color: var(--color-grey-100);
    border-radius: var(--border-radius-medium);
    padding: var(--spacing-medium-small);
    > * + * {
        margin-top: var(--spacing-medium-small) !important;
    }
`;

const Result = styled.div`
    border-top: 1px solid var(--color-grey-100);
    padding-top: var(--spacing-medium-large);
`;

const TaskStep = styled.section`
    // todo?
`;

const TaskStepCommand = styled.section`
    flex: 1 1 auto;
`;

const TaskStepContent = styled.div`
    margin-left: var(--spacing-larger);
    ${taskStepContent}
`;

const TaskStepHeader = styled.header`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TaskStepCollapse = styled.div`
    color: var(--color-grey-400);
    cursor: pointer;
    font-size: var(--spacing-medium);
`;

const TaskStepList = styled.div`
    > * + * {
        margin-top: var(--spacing-medium-large);
    }
`;

const TaskStepNumber = styled.div`
    ${taskStep}
    font-size: var(--spacing-large);
    width: var(--spacing-larger);
`;

const TaskStepSection = styled.section`
    // Copied from BodyContainer in ditaElementRules.tsx
    > * + * {
        margin-top: var(--spacing-medium-large) !important;
    }
`;

const TaskSubStepContent = styled.div`
    margin-left: var(--spacing-large);
    ${taskStepContent}
`;

const TaskSubStepNumber = styled.div`
    ${taskStep}
    font-size: var(--spacing-medium);
    width: var(--spacing-large);
`;

export {
    PrereqWrapper,
    Result,
    TaskStep,
    TaskStepCollapse,
    TaskStepCommand,
    TaskStepContent,
    TaskStepHeader,
    TaskStepList,
    TaskStepNumber,
    TaskStepSection,
    TaskSubStepContent,
    TaskSubStepNumber,
}
