import { css } from "@emotion/react";
import styled from "@emotion/styled";

const upgradeInstruction = css`
	font-family: var(--font-secondary);
	font-weight: var(--font-weight-bold);
	flex: 0 0 auto;
	color: var(--color-grey-800);
`;

const UpgradeInstruction = styled.section`
	margin-bottom: var(--spacing-small);

	> section {
	 	margin-bottom: var(--spacing-large);
	}
`;

const UpgradeInstructionHeader = styled.header`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const UpgradeInstructionNumber = styled.div`
	${upgradeInstruction}
    margin-left: var(--spacing-medium-small);
	font-size: var(--spacing-medium-large);
	width: var(--spacing-large);
`;

const UpgradeInstructionCommand = styled.section`
	flex: 1 1 auto;
`;

const UpgradeInstructionContent = styled.section`
	margin-left: var(--spacing-larger);
`;

const UpgradeInstructionCheckbox = styled.div`
	color: var(--color-grey-400);
	cursor: pointer;
	font-size: var(--spacing-medium);
`;

export {
	UpgradeInstruction,
	UpgradeInstructionHeader,
	UpgradeInstructionNumber,
	UpgradeInstructionCommand,
	UpgradeInstructionContent,
	UpgradeInstructionCheckbox,
};
